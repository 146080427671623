<template>
<teleport
  :to="parent"
  v-if="modelValue">

  <Transition
    name="fade"
    appear
    @before-enter="$emit('enter')">

    <div class="v-modal__underlay"
      @click="close()">

      <Transition
        name="pop"
        appear>

        <div class="v-modal" 
          :style="styleModal"
          @click.stop="_ => {}">

          <div class="v-modal__menu-top">
            <i class="material-symbols v-modal__cross" 
              @click="close()">
              close
            </i>
          </div>

          <div class="v-modal__content"
            :style="{ alignItems: align }">
            <div class="v-modal__title"
              v-if="title" 
              style="text-align:center">
              {{ title }}
            </div>
            <slot name="content"/>
          </div>
          <div class="v-modal__buttons"
            v-if="!!$slots.buttons">
            <slot name="buttons"/>
          </div>

        </div>

      </Transition>

    </div>

  </Transition>

</teleport>
</template>

<script>
import './v-modal-styles.scss'
import VDropdown from '@/components/ui/v-dropdown.vue'
export default {
  emits: [
    'update:modelValue',
    'enter',
    'close',
  ],
  components: {
    VDropdown,
  },
  props: {
    parent: { type: String, required: false, default: 'body' },
    modelValue: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    width: { type: String, required: false, default: "21em" },
    style: { type: Object, required: false, default: {} },
    align: { type: String, required: false, default: 'stretch' },
  },
  data() {
    return {
      modalCount: 0,
      styleModal: {},
    }
  },
  mounted() {
    this.modalCount = document.querySelectorAll('.modal-underlay').length
    this.styleModal = {
      width: this.width,
      zIndex: this.modalCount + 5,
      ...this.style
    }
  },
  unmounted() {
    document.body.style.overflowY = 'auto'
  },
  methods: {
    close() {
      this.$emit("close")
      this.$emit("update:modelValue", false)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        document.body.style.overflowY = val ? 'hidden' : 'auto'
      }
    }
  },
}
</script>