<template>
<button class="v-button-animated"
  v-bind="{ disabled }"
  @click.stop="click()">

  <transition
    name="v-button-animated"
    mode="out-in"
    :appear="appear">

    <div class="v-button-animated__button"
      v-if="flag"
      v-bind="{ style }">
      <div class="v-button-animated__label">
        <span v-if="label">
          {{ label }}
        </span>
        <i :class="`material-symbols${isFilled ? '' : '-outlined'}`"
          v-if="icon">
          {{ icon }}
        </i>
      </div>
    </div>

    <div class="v-button-animated__button"
      v-else>
      <div class="v-button-animated__label">
        <span v-if="label">
          {{ label }}
        </span>
        <i :class="`material-symbols${isFilled ? '' : '-outlined'}`"
          v-if="icon">
          {{ icon }}
        </i>
      </div>
    </div>

  </transition>

  <div class="v-button-animated__tooltip"
    v-if="tooltipMessage"
    v-html="tooltipMessage"
    :style="{ [tooltipAlign]: 0 }">
  </div>

</button>
</template>

<script>
export default {
  props: {
    appear: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    isFilled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    tooltipMessage: { type: String, required: false, default: undefined },
    tooltipAlign: { type: String, required: false, default: 'right' },
    direction: { type: String, required: false, default: undefined },
    duration: { type: String, required: false, default: "200ms" },
    size: { type: String, required: false, default: "1em" },
    color: { type: String, required: false, default: "inherit" },
    background: { type: String, required: false, default: "transparent" },
    style: { type: [String, Object], required: false, default: undefined },
    event: { type: String, required: false, default: undefined },
    payload: { type: Object, required: false, default: undefined },
    action: { type: Function, required: false, default: undefined },
  },
  data() {
    return {
      flag: true,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      rotate: 0,
    }
  },
  created() {
    const dirs = this.direction?.split('-') ?? []
    dirs.forEach(e => {
      this[e] = e=='rotate' ? 180 : 70
    })
  },
  methods: {
    click() {
      this.flag = !this.flag
      if (this.action) this.action()
      if (this.event) {
        this.$capture(this.event, this.payload)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-button-animated {
  margin: 0;
  padding: 0;
  min-width: v-bind(size);
  min-height: v-bind(size);
  //font-size: v-bind(size);
  display: inline-block;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  &:hover + &__button { opacity: .75; }
  &:active + &__button { opacity: .5; }
  &:disabled {
    pointer-events: none;
    opacity: .25;
  }
  &__button {
    z-index: 0;
    position: absolute;
    top: 0; left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: clip;
    font-size: v-bind(size);
    //border-radius: 50%;
    background: v-bind(background);
    color: v-bind(color);
    transition: v-bind(duration);
    -webkit-tap-highlight-color: transparent;
  }
  &__label {
    z-index: 10;
    display: flex;
    align-items: center;
    gap: .35em;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    font-size: inherit;
    font-family: 'Inter';
    color: inherit;
    i {
      font-weight: 500;
      color: inherit;
    }
  }
  &__tooltip {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    padding: .25em .75em;
    border-radius: $border-radius;
    background: #0004;
    color: #fff;
    font-size: initial;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;
  }
  &__button:hover + &__tooltip {
    visibility: visible;
  }
  &-enter-from {
    top: 0;
    .v-button-animated__label { 
      top: calc( calc(50% + calc(v-bind(top) * 1%)) - calc(v-bind(bottom) * 1%) );
      left: calc( calc(50% + calc(v-bind(left) * 1%)) - calc(v-bind(right) * 1%) );
      transform: translate(-50%,-50%) rotate(calc( v-bind(rotate) * -1deg ));
    }
  }
  &-enter-active {
    transition: v-bind(duration);
    .v-button-animated__label { 
      transition: v-bind(duration) linear;
    }
  }
  &-leave-to {
    top: 1px;
    .v-button-animated__label { 
      top: calc( calc(50% - calc(v-bind(top) * 1%)) + calc(v-bind(bottom) * 1%) );
      left: calc( calc(50% - calc(v-bind(left) * 1%)) + calc(v-bind(right) * 1%) );
      transform: translate(-50%,-50%) rotate(calc( v-bind(rotate) * 1deg ));
    }
  }
  &-leave-active {
    transition: v-bind(duration);
    .v-button-animated__label { 
      transition: v-bind(duration) linear;
    }
  }
}
</style>