<template>
<div class="v-textarea">
  <div class="v-textarea__label"
    v-if="label">
    {{ label }}
    <span v-if="required" style="color:red">*</span>
  </div>
  <div class="v-textarea__wrapper">
    <textarea class="v-textarea__input"
      ref="textarea"
      :class="{ invalid: v$.$error }"
      v-model="content"
      v-bind="bindingTextarea"
      @input="onInput($event)"
      @paste="event => onPaste(event)">
    </textarea>
  </div>
  <div class="v-textarea__error"
    v-if="v$.modelValue.$error">
    <div v-if="v$.modelValue.required?.$invalid">
      Поле має бути заповнене
    </div>
  </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  props: {
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    placeholder: { type: String, required: false, default: undefined },
    maxLength: { type: [String, Number], required: false, default: 256 },
    rows: { type: [String, Number], required: false, default: 1 },
    style: { type: [String, Object], required: false, default: undefined },
  },
  data() { return {
    v$: useVuelidate(),
  }},
  validations() { return {
    modelValue: {
      required: this.required ? required : true,
    }
  }},
  computed: {
    bindingTextarea() {
      return {
        disabled: this.disabled,
        placeholder: this.placeholder ?? this.label,
        maxlength: this.maxLength,
        rows: this.rows,
        style: this.style,
      }
    },
    content: {
      get() { return this.modelValue },
      set(val) { this.$emit('update:modelValue', val) }
    }
  },
  mounted() {
    this.$nextTick(_ => this.onResize())
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    click() {
      this.$refs.textarea.focus()
    },
    onResize() {
      const el = this.$refs.textarea
      this.$nextTick(_ => {

        el.style.height = "auto"
      el.style.height = (el.scrollHeight) + "px"
      })
    },
    onInput() {
      this.$emit('update:modelValue', this.modelValue)
      this.$emit('update', this.modelValue)
    },
    onPaste(event) {
      const text = event.clipboardData.getData("text")
      this.$emit('paste-text', text.split(/[\r?\n]+/).map(e => e.trim()))
    },
  },
  watch: {
    modelValue() {
      this.$nextTick(_ => this.onResize())
    },
  }
}
</script>

<style lang="scss" scoped>
.v-textarea {
  display: flex;
  flex-direction: column;
  gap: .25em;
  &__label {
    line-height: 1.5;
    font-weight: 500;
    color: #000;
  }
  &__input {
    display: block;
    width: 100%;
    min-height: 2em;
    padding: .75em 1em;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Inter';
    font-size: inherit;
    line-height: 1.5;
    text-align: inherit;
    border-radius: 4px;
    background-color: #ebebeb;
    color: #333;
    &:hover { filter: brightness(1.02); }
    &:focus { background-color: $clight; }
    &.invalid { background: #ff4d4d88; }
    &:focus + span {
      left: .35em;
      width: calc(100% - .7em);
    }
    &:disabled {
      color: #404040
    }
    &::placeholder {
      color: #0003;
    }
  }
  &__error {
    margin-top: .5em;
    color: #ff4d4d;
    font-size: .8em;
    font-weight: 500;
  }
}
</style>