<template>
<div class="v-input-text"
  :class="{ centered: direction=='row' }">

  <div class="v-input-text__label"
    v-if="label">
    {{ label }}
    <span v-if="required" style="color:red">*</span>
  </div>

  <div class="v-input-text__body-wrapper">

    <div class="v-input-text__body"
      :class="{ invalid: v$.$error }">

      <div class="v-input-text__content">
        <div class="v-input-text__prefix"
          v-if="hasControls || prefix">
          <div class="text-primary"
            v-if="prefix">
            {{ prefix }}
          </div>
          <div class="material-symbols-outlined v-input-text__icon"
            v-if="hasControls"
            @click="incrementModel(-step)">
            remove
          </div>
        </div>
        <div class="v-input-text__input-wrapper">
          <input class="v-input-text__input"
            ref="input"
            v-if="!rows"
            v-model.trim="model"
            v-bind="bindingInput"
            @paste="$event => pasteText($event)">
          <textarea class="v-input-text__input"
            v-else
            v-model="model"
            v-bind="bindingInput">
          </textarea>
        </div>
        <div class="v-input-text__postfix"
          v-if="hasControls || postfix">
          <div class="material-symbols-outlined v-input-text__icon"
            v-if="hasControls"
            @click="incrementModel(step)">
            add
          </div>
          <div class="text-primary"
            v-if="postfix">
            {{ postfix }}
          </div>
        </div>
      </div>
      
      <i class="material-symbols v-input-text__icon"
        v-if="isPassword"
        @click="togglePassword()">
        {{ isPasswordVisible ? "visibility" : "visibility_off" }}
      </i>
      <i class="material-symbols v-input-text__icon"
        v-if="isCopiable"
        @click="copyToClipboard()">
        {{ isCopied ? "done" : "content_copy" }}
      </i>

      <slot/>

    </div>

    <div class="text-error"
      v-if="hasErrorMessage && v$.modelValue.$error">
      <div v-if="v$.modelValue.required?.$invalid">Поле має бути заповнене</div>
      <div v-else-if="v$.modelValue.minValue?.$invalid">Значення має бути більшим або рівним {{ min }}</div>
      <div v-else-if="v$.modelValue.maxValue?.$invalid">Значення має бути меншим або рівним {{ max }}</div>
      <div v-else-if="v$.modelValue.minLength?.$invalid">Поле має містити щонайменше {{ minLength }} символів</div>
      <div v-else-if="v$.modelValue.email?.$invalid">Неправильний формат email</div>
      <div v-else-if="v$.modelValue?.sameAs.$invalid">Поля мають співпадати</div>
      <div v-else>
        <div v-for="(validation, i) in validations">
          <div v-if="v$.modelValue[`v${i}`]?.$invalid">
            {{ validation?.text }}
          </div>
        </div>
      </div>
    </div>
    
  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minValue, maxValue, minLength, email, sameAs } from '@vuelidate/validators'
export default {
  emits: [
    'update:modelValue',
    'update',
    'paste',
  ],
  props: {
    isCopiable: { type: Boolean, required: false, default: false },
    hasErrorMessage: { type: Boolean, required: false, default: true },
    hasControls: { type: Boolean, required: false, default: false },
    autofocus: { type: Boolean, required: false, default: false },
    modelValue: { type: [String, Number], required: false, default: undefined },
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    size: { type: [String, Number], required: false, default: 20 },
    type: { type: String, required: false, default: 'text' },
    direction: { type: String, required: false, default: 'column' },
    pattern: { type: String, required: false, default: undefined },
    prefix: { type: String, required: false, default: undefined },
    postfix: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    placeholder: { type: String, required: false, default: undefined },
    min: { type: Number, required: false, default: undefined },
    max: { type: Number, required: false, default: undefined },
    step: { type: [String, Number], required: false, default: 1 },
    minLength: { type: [String, Number], required: false, default: undefined },
    maxLength: { type: [String, Number], required: false, default: 256 },
    sameAs: { type: String, required: false, default: undefined },
    rows: { type: [String, Number], required: false, default: undefined },
    color: { type: String, required: false, default: '#404040' },
    validations: { type: Array, required: false, default: [] },
  },
  mounted() {
    if (this.autofocus) this.$refs.input.focus()
  },
  data() {
    return {
      v$: useVuelidate(),
      isPassword: this.type=="password",
      isPasswordVisible: false,
      isCopied: false,
    }
  },
  validations() {
    return {
      modelValue: {
        required: this.required ? required : true,
        email: this.type=="email" ? email : true,
        minValue: (typeof this.min == 'number') ? minValue(this.min) : true,
        maxValue: (typeof this.max == 'number') ? maxValue(this.max) : true,
        minLength: (typeof this.minLength == 'number') ? minLength(this.minLength) : true,
        sameAs: this.sameAs===undefined ? true : sameAs(this.sameAs),
        ...Object.fromEntries(this.validations.map((e,i) => ([ 'v' + i, str => e?.regexp?.test(str) ]))),
      }
    }
  },
  computed: {
    typeDisplayed() {
      return (this.type=="email" || this.isPasswordVisible) ? "text" : this.type
    },
    bindingInput() {
      return {
        type: (this.type=="email" || this.isPasswordVisible) ? "text" : this.type,
        value: this.modelValue,
        disabled: this.disabled,
        readonly: this.readonly,
        pattern: this.pattern,
        maxlength: this.maxLength==Infinity ? undefined : String(this.maxLength),
        placeholder: this.placeholder ?? this.label,
        rows: this.rows,
        size: String(this.size),
      }
    },
    model: {
      get() { return this.modelValue },
      set(val) {
        this.$emit('update:modelValue', val===undefined ? null : val)
        this.$emit('update', val)
      }
    },
  },
  methods: {
    pasteText(event) {
      const text = (event.clipboardData || window.clipboardData).getData("text")
      this.$emit('paste', text)
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    async copyToClipboard() {
      this.isCopied = true
      await navigator.clipboard.writeText(this.modelValue)
    },
    incrementModel(delta) {
      this.model = (this.model || 0) + delta
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input-text {
  display: flex;
  flex-direction: v-bind(direction);
  gap: .35em;
  &.centered { align-items: center; }
  &__label {
    line-height: 1.5;
    font-weight: 500;
    color: #000;
  }
  &__body {
    display: flex;
    align-items: center;
    gap: .7em;
    padding: .7em 1em;
    border-radius: 7px;
    background-color: $clightgray;
    color: #333;
    &:hover { filter: brightness(1.02); }
    &:focus-within { background-color: $clight; }
    &.invalid {
      background: #ff4d4d88;
    }
  }
  &__content {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
  }
  &__prefix {
    display: flex;
    align-items: center;
    gap: .5em;
    position: relative;
    left: .5em;
  }
  &__postfix {
    display: flex;
    align-items: center;
    gap: .5em;
    position: relative;
    right: .5em;
  }
  &__input-wrapper {
    flex-grow: 1;
    width: 100%;
  }
  &__icon {
    cursor: pointer;
    font-size: 1.75em;
    color: #777;
  }
  &__input {
    width: 100%;
    min-height: 2em;
    outline: none;
    border-style: hidden;
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    background: transparent;
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    &:read-only { opacity: .7; }
    &:-webkit-autofill {
      background: transparent;
      -webkit-background-clip: text;
    }
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin: 0;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      opacity: .5;
    }
    &::placeholder {
      color: $cdarkgray;
      font-weight: normal;
    }
  }
  &__error {
    margin: 5px 0 5px 0;
    text-align: left;
    color: #ff4d4d;
    font-size: .8em;
    font-weight: 500;
  }
}
</style>