<template>
<div class="v-dropdown"
  :class="{ centered: direction=='row' }">

  <div class="v-dropdown__label"
    v-if="label">
    {{ label }}
  </div>

  <div class="v-dropdown__body"
    v-click-outside.stop.prevent="hide"
    :class="{ [mode]: true, error: v$.$error }">

    <div class="v-dropdown__title"
      ref="v-dropdown-title"
      :class="{ pale: placeholder==titleModel?.label }"
      @click="toggle()"
      :style="title.style">
      <img class="v-dropdown__item-image"
        v-if="titleModel?.src"
        :src="titleModel.src">
      <i class="v-dropdown__item-icon"
        v-if="titleModel?.icon"
        :class="`material-symbols${titleModel.isFilled ? '' : '-outlined'}`"
        :style="{ color: titleModel.iconColor }">
        {{ titleModel.icon }}
      </i>
      <span class="v-dropdown__title-label"
        v-if="titleModel?.label"
        v-html="titleModel.label">
      </span>
      <i class="material-symbols v-dropdown__item-icon"
        v-if="hasArrow">
        {{ isUnfolded ? "expand_less" : "expand_more" }}
      </i>
      <slot/>
      
    </div>

    <div class="v-dropdown__items"
      ref="v-dropdown-body"
      v-if="isUnfolded == true"
      :style="{ [align]: 0 }">
      <button class="v-dropdown__item"
        v-for="(item, i) in itemsDisplayed"
        :disabled="item.disabled ?? false"
        v-show="item.visible ?? true"
        @click.stop="select(i)"
        :style="item.style">
        <img class="v-dropdown__item-image"
          v-if="item.src"
          :src="item.src"
          alt>
        <i class="v-dropdown__item-icon"
          v-if="item.icon"
          :class="`material-symbols${item.isFilled ? '' : '-outlined'}`"
          :style="{ color: item.iconColor }">
          {{ item.icon }}
        </i>
        <span class="v-dropdown__item-label"
          v-if="item.label"
          v-html="item.label">
        </span>
      </button>
    </div>

  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  emits: [
    'update:modelValue',
    'select',
    'change',
  ],
  props: {
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    hasArrow: { type: Boolean, required: false, default: false },
    hasUndefined: { type: Boolean, required: false, default: false },
    modelValue: { type: [Boolean, Number, String], required: false, default: undefined },
    direction: { type: String, required: false, default: 'column' },
    placeholder: { type: String, required: false, default: 'Без фільтру' },
    label: { type: String, required: false, default: undefined },
    title: { type: Object, required: false, default: {} },
    items: { type: Array, required: true, default: [] },
    align: { type: String, required: false, default: 'left' },
    mode: { type: String, required: false, validator: val => ['none', 'outlined', 'underlined'].includes(val), default: 'none' },
    height: { type: String, required: false, default: 'auto' },
  },
  data() {
    return {
      v$: useVuelidate(),
      isUnfolded: false,
    }
  },
  validations() {
    return {
      modelValue: {
        required: this.required ? required : true,
      }
    }
  },
  computed: {
    titleModel() {
      let title
      if (!!this.$slots.default) return
      if (this.title?.icon || this.title?.label) title = this.title
      else title = this.itemsDisplayed.find(e => e.value==this.modelValue)
      return title
    },
    itemsDisplayed() {
      return [
        ...this.hasUndefined ? [
          { label: this.placeholder, value: undefined, style: 'color:#0004' }
        ] : [],
        ...this.items,
      ]
    },
  },
  methods: {
    select(i) {
      const item = this.itemsDisplayed[i]
      if (item.action) item.action()
      this.$emit("update:modelValue", item.value)
      this.$emit("select", item.value)
      if (this.modelValue != item.value) this.$emit('change')
      this.isUnfolded = false
    },
    toggle() {
      if (this.disabled) return
      this.isUnfolded = !this.isUnfolded
      this.$nextTick(_ => {
        if (this.$refs['v-dropdown-body']) {
          this.$refs['v-dropdown-body'].style.top = `${this.$refs['v-dropdown-title'].offsetHeight + 5}px`
        }
      })
    },
    hide() {
      this.isUnfolded = false
    },
  }
}
</script>

<style lang="scss" scoped>
.v-dropdown {
  display: inline-flex;
  flex-direction: v-bind(direction);
  gap: .25em .75em;
  &.centered { align-items: center; }
  &__label {
    line-height: 1.5;
    font-weight: 500;
    color: #000;
  }
  &__title {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5em;
    height: 100%;
    //padding: .5em .7em;
    &:hover { opacity: .75; }
    &:active { opacity: .5; }
    &.pale { opacity: .35; }
  }
  &__title-label {
    margin-right: auto;
    padding: 5px 0;
  }
  &__body {
    flex-grow: 1;
    position: relative;
    height: 100%;
    &.outlined {
      border-radius: 7px;
      background-color: $clightgray;
      color: #333;
      & > div:first-child { padding: .6em 1em; }
    }
    &.underlined {
      border-bottom: 1px solid #0004;
      & > div:first-child { padding: 0 .7em; }
    }
    &.error { border-color: red; }
  }
  &__items {
    z-index: 10;
    position: absolute;
    min-width: 100%;
    max-height: v-bind(height);
    padding: .35em 0;
    border: 1px solid $cmediumgray;
    border-radius: $border-radius;
    background: white;
    overflow-y: auto;
  }
  &__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: .75em;
    cursor: pointer;
    min-width: 100%;
    height: 3em;
    padding: 0 1em;
    font-weight: normal;
    color: initial;
    line-height: 1.5;
    text-transform: none;
    white-space: nowrap;
    &:hover { background-color: #0001; }
    &:active { background-color: #0002; }
    &:disabled {
      cursor: initial;
      opacity: .35;
    }  
  }
  &__item-image {
    width: 1.5em;
    display: block;
    object-fit: contain;
    border-radius: 50%;
  }
  &__item-icon {
    font-size: 1.5em;
  }
}
</style>