<template>
<div class="v-input-image">

  <div class="v-input-image__overlay"
    v-if="isCropperVisible">
    <div class="v-input-image__container">
      <div class="v-input-image__buttons">
        <i class="material-symbols button-icon"
          @click="deleteCropper">
          arrow_back
        </i>
        <i class="material-symbols button-icon"
          @click="cropImage">
          done
        </i>
      </div>
      <div ref="cropper">
        <img class="v-input-image__image"
          ref="image"
          :src="src">
      </div>
    </div>
  </div>

  <input class="v-input-image__input"
    type="file"
    ref="input"
    :id="`input-${$uuid}`"
    accept="image/*"
    @change="createCropper($event)"
    @cancel="$emit('cancel')">

  <div v-if="!isHidden">

    <div class="v-input-image__label"
      v-if="label">
      {{ label }}
    </div>

    <div class="v-input-image__preview"
      v-if="hasPreview && srcCropped">
      <div class="v-input-image__preview-name">
        {{ name }}
      </div>
      <img class="v-input-image__preview-image"
        :src="srcCropped">
    </div>

    <div class="button-pale v-input-image__button"
      v-if="modelValue"
      style="color:red"
      @click="deleteImage()">
      <i class="material-symbols-outlined">hide_image</i>
      {{ labelDelete ?? "Видалити" }}
    </div>

    <div class="v-input-image__content"
      v-if="!isUploading && !modelValue">
      <label class="v-input-image__input-label"
        :for="`input-${$uuid}`"
        v-if="!srcCropped">
        <slot>
          <div class="button-pale v-input-image__button">
            <i class="material-symbols-outlined">image</i>
            {{ labelUpload ?? "Завантажити" }}
          </div>
        </slot>
      </label>
    </div>

    <div class="v-input-image__spinner"
      v-if="isUploading"
      v-loading="isUploading">
    </div>

  </div>

</div>
</template>

<script>
import Cropper from "cropperjs"
import 'cropperjs/src/css/cropper.css'
export default {
  emits: [
    'update:modelValue',
    'update:v',
    'preview',
    'uploading',
    'uploaded',
    'submit',
    'cancel',
    'delete',
  ],
  props: {
    isHidden: { type: Boolean, required: false, default: false },
    hasPreview: { type: Boolean, required: false, default: false },
    immediate: { type: Boolean, required: false, default: false },
    modelValue: { type: [Boolean, Number], required: false, default: false },
    v: { type: [String, Number], required: false, default: 0 },
    label: { type: String, required: false, default: undefined },
    labelUpload: { type: String, required: false, default: undefined },
    labelDelete: { type: String, required: false, default: undefined },
    width: { type: [Number, String], required: false, default: undefined },
    aspectRatio: { type: [Number, String], required: false, default: undefined },
    fileName: { type: String, required: false, default: undefined },
    uploadURL: { type: String, required: false, default: undefined },
    formFields: { type: Object, required: false, default: undefined },
  },
  data() {
    return {
      cropper: null,
      src: null,
      srcCropped: null,
      imageFile: null,
      name: undefined,
      form: new FormData(),
      reader: new FileReader(),
      isCropperVisible: false,
      isUploading: false,
      version: 0,
    }
  },
  methods: {
    click() {
      this.$refs.input.click()
    },
    generateV() {
      return Math.floor(Math.random() * 9e8) + 1e8
    },
    async createCropper(event) {
      const file = event.target.files[0]
      this.name = this.fileName ?? file.name
      // if (this.cropper) {
      //   this.cropper?.destroy()
      // }
      await new Promise(resolve => {
        this.reader.readAsDataURL(file)
        this.reader.onload = event => {
          this.isCropperVisible = true
          this.src = event.target.result
          resolve()
        }
      })
      this.$nextTick(_ => {
        this.$emit('preview')
        this.cropper = new Cropper(this.$refs.image, {
          aspectRatio: Number(this.aspectRatio),
          viewMode: 0,
          autoCropArea: 1,
          responsive: true,
        })
      })
    },
    async cropImage() {
      this.src = null
      this.isCropperVisible = false
      const canvas = await this.cropper.getCroppedCanvas({
        width: this.width,
      })
      this.srcCropped = canvas.toDataURL('image/webp')
      await new Promise(resolve => {
        canvas.toBlob(async blob => {
          this.imageFile = new File([ blob ], this.fileName)
          this.$emit('submit', blob, this.name)
          this.version = this.generateV()
          if (this.immediate) await this.upload()
          this.$emit('update:modelValue', true)
          this.$emit('update:v', this.version)
          resolve()
        }, 'image/jpg')
      })
      this.$refs.input.value = ''
    },
    unsetCropper() {
      this.src = null
      this.imageFile = null
      this.form = new FormData()
      this.isUploading = false
      this.$refs.input.value = ''
    },
    deleteCropper() {
      this.isCropperVisible = false
      this.unsetCropper()
      this.$emit('cancel')
    },
    deleteImage() {
      this.src = null
      this.srcCropped = null
      this.imageFile = null
      this.$emit('update:modelValue', false)
      this.$emit('update:v', 0)
      this.$emit('delete')
    },
    async upload() {
      this.$emit('uploading')
      this.isUploading = true
      this.model = this.formFields
      this.model.version = this.version
      this.model.file = this.imageFile
      const { data } = await this.$api.postForm(this.uploadURL, this.model)
      this.$emit('uploaded', data)
      this.unsetCropper()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input-image {
  :deep(cropper-canvas) {
    height: 20em;
    max-height: 100vh;
    max-width: 100vw;
  }
  &__label {
    margin-bottom: .75em;
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__input {
    display: none
  }
  &__preview {
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__preview-image {
    height: 3em;
    object-fit: contain;
  }
  &__input-label {
    cursor: pointer;
    width: 100%;
    &:hover { opacity: .85; }
    &:active { opacity: .5; }
    &:disabled {
      cursor: unset;
      opacity: .25;
    }
  }
  &__spinner {
    width: 4em;
    height: 4em;
  }
  &__overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0,0,0,.5);
  }
  &__container {
    z-index: 9;
    width: min(26em, 95%);
    border-radius: $border-radius;
    box-shadow: 0 2px 6px #0004;
    background: #fff;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
  }
  &__button {
    color: $cmain;
  }
  &__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>