<template>
<div class="v-tabs-header">

  <div class="v-tabs-header__current"
    v-if="isLabelVisible">
    <i class="material-symbols v-tabs-header__icon">
      {{ items[modelValue]?.icon }}
    </i>&nbsp;
    {{ items[modelValue]?.label }}
  </div>

  <slot>
    <div></div>
  </slot>

  <div class="v-tabs-header__tabs"
    :style="style">

    <div class="v-tabs-header__titles">
      <div class="v-tabs-header__title"
        v-for="(item, i) in items"
        @click="selectTab(i)">
        <i class="material-symbols v-tabs-header__icon"
          v-if="item.icon">
          {{ item.icon }}
        </i>
        <span class="v-tabs-header__label"
          v-if="hasLabels && item.label">
          {{ item.label }}
        </span>
      </div>
    </div>

    <div class="v-tabs-header__hrs">
      <TransitionGroup name="list">
        <hr class="v-tabs-header__hr"
          :class="{ selected: !key }"
          v-for="key in keys"
          :key="key">
      </TransitionGroup>
    </div>

  </div>
  
</div>
</template>

<script>
export default {
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: { type: Number, required: false, default: 0 },
    items: { type: Array, required: true, default: [] },
    hasLabels: { type: Boolean, required: false, default: true },
    isLabelVisible: { type: Boolean, required: false, default: false },
    colorActive: { type: String, required: false, default: '#140F2E' },
    style: { type: Object, required: false, default: {} },
  },
  data() {
    return {
      keys: [],
    }
  },
  created() {
    this.setKeys(this.itemsDisplayed?.length)
  },
  computed: {
    itemsDisplayed() {
      return this.items.filter(e => e.visible ?? true)
    }
  },
  methods: {
    async selectTab(i) {
      if (i >= this.itemsDisplayed.length) return
      if (this.itemsDisplayed[i]?.action) {
        await this.itemsDisplayed[i].action()
      }
      const j = this.keys.indexOf(0)
      const b = this.keys[j]
      this.keys[j] = this.keys[i]
      this.keys[i] = b
      this.$emit('update:modelValue', i)
    },
    setKeys(length) {
      this.keys = Array.from({ length }, (_,i) => i)
      this.selectTab(Math.min(this.modelValue, length - 1))
    },
  },
  watch: {
    modelValue(i) {
      this.selectTab(i)
    },
    'itemsDisplayed.length'(val) {
      this.setKeys(val)
    },
  }
}
</script>

<style lang="scss" scoped>
.v-tabs-header {
  margin-bottom: 1.5em;
  user-select: none;
  &__tabs {
    cursor: pointer;
    display: inline-block;
    width: 100%;
  }
  &__current {
    margin-left: 10px;
    font-size: 1.15em;
    font-weight: bold;
  }
  &__titles, &__hrs {
    display: flex;
    align-items: center;
    color: inherit;
    * {
      flex-basis: 100%;
      text-align: center;
    }
  }
  &__title {
    padding: 10px 5px;
    color: inherit;
    overflow: clip;
    white-space: nowrap;
    &.selected {
      color: v-bind(colorActive);
    }
  }
  &__icon {
    font-size: 1.33em;
    font-weight: 400;
    vertical-align: middle;
  }
  &__label {
    margin-left: 5px;
    vertical-align: middle;
  }
  &__hrs {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0; right: 0;
      border-bottom: 1px solid;
      opacity: .5;
    }
  }
  &__hr {
    position: relative;
    margin: 0;
    border: none;
    color: #050505;
    &.selected {
      background: v-bind(colorActive);
      height: 4px;
    }
  }
}

.list-move {
  transition: all .25s ease;
}
</style>