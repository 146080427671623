<script>
import { h, Transition } from 'vue'
import './v-accordion-styles.scss'
export default {
  props: {
    modelValue: { type: Number, required: false, default: undefined },
    areArrowsVisible: { type: Boolean, required: false, default: true },
  },
  mounted() {
    this.filterTags(this.$slots.default(), 'accordion-header', 'headers')
    this.filterTags(this.$slots.default(), 'accordion-content', 'contents')
  },
  data() {
    return {
      headers: [],
      contents: [],
    }
  },
  computed: {
    vnodes() {
      return this.headers.flatMap((header,i) => [
        h('div', 
          { class: [header.props?.class, 'v-accordion__header'] },
          [
            ...this.areArrowsVisible ? [h('i', { 
              class: 'material-symbols v-accordion__header-icon', 
              innerHTML: i==this.modelValue ? 'expand_less' : 'expand_more' 
            })] : [],
            h(header, {
              onClick: _ => this.$emit('update:modelValue', i==this.modelValue ? -1 : i),
            }),
          ]
        ),
        h(Transition, 
          {
            name: 'expand',
            mode: 'out-in',
            onEnter: el => this.transitionEnter(el),
            onLeave: el => this.transitionLeave(el)
          },
          _ => {
            if (i == this.modelValue) return h('div', {}, this.contents[i])
          }
        )
      ])
    },
  },
  render() {
    return h('div', {}, { default: _ => this.vnodes })
  },
  methods: {
    filterTags(vnodesList, tag, propName) {
      vnodesList.forEach(e => {
        if (e.type.name == tag) this[propName].push(e)
        if (e.children?.length) {
          this.filterTags(e.children, tag, propName)
        }
      })
    },
    transitionEnter(el) {
      el.style.visibility = 'hidden'
      el.style.height = 'auto'
      this.$nextTick(_ => {
        const height = el.scrollHeight + 'px'
        el.style.visibility = 'visible'
        el.style.height = 0
        requestAnimationFrame(_ => {
          el.style.height = height
        })
      })
    },
    transitionLeave(el) {
      const height = getComputedStyle(el).height
      el.style.height = height
      requestAnimationFrame(_ => {
        el.style.height = 0
      })
    }
  },
  watch: {
    '$slots.default'() {
      this.headers = []
      this.contents = []
      this.filterTags(this.$slots.default(), 'accordion-header', 'headers')
      this.filterTags(this.$slots.default(), 'accordion-content', 'contents')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-accordion {
  &__header {
    cursor: pointer;
    position: relative;
  }
  &__header-icon {
    z-index: 2;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    font-size: 2em;
    color: #777;
  }
}
</style>