<template>
<div class="v-viewer"
  v-loading.large="isLoading">

  <iframe class="v-viewer__iframe"
    :src="ext=='pdf' ? src : urlFull"
    @load="onLoad()">
  </iframe>

  <div class="v-viewer__toolbar"
    v-if="ext=='pdf' && isToolbarVisible">
    <button class="material-symbols button-icon"
      @click="download">
      download
    </button>
    <button class="material-symbols button-icon"
      @click="$navigateLink(urlFull, { isBlank: true })">
      open_in_new
    </button>
  </div>

</div>
</template>

<script>
export default {
  emits: [
    'ready',
  ],
  props: {
    isToolbarVisible: { type: Boolean, required: false, default: true },
    url: { type: String, required: true, default: undefined },
    ext: { type: String, required: true, default: undefined },
    name: { type: String, required: true, default: undefined },
    aspectRatio: { type: [Number, String], required: false, default: 1.414 / 1 },
  },
  async mounted() {
    this.isLoading = true
    if (this.ext=='pdf') await this.loadFile()
  },
  data() {
    return {
      isLoading: false,
      src: undefined,
      blob: {},
      pdf: {},
    }
  },
  computed: {
    urlFull() {
      if (this.ext=='pdf') return `${this.url}?size=pdf#view=fit&navpanes=0&scrollbar=0`
      if (['doc','docx','ppt','pptx','xls','xlsx'].includes(this.ext)) return `https://docs.google.com/gview?url=${this.url}&embedded=true`
      //if (this.ext=='docx' || this.ext=='pptx' || this.ext=='xlsx') return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
      console.log(this.url)
      if (this.url.includes('drive.google.com')) return this.url.replace('view?usp=sharing', 'preview')
      if (this.url.includes('docs.google.com')) return `https://drive.google.com/file/d/${this.url.split('/')[5]}/preview`
      return this.url
    }
  },
  methods: {
    onLoad() {
      this.$emit('ready')
      this.isLoading = false
    },
    async loadFile() {
      const res = await fetch(this.url)
      this.blob = await res.blob()
      this.src = URL.createObjectURL(this.blob)
    },
    download() {
      this.$download(this.blob, 'application/pdf', this.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-viewer {
  &__iframe {
    display: block;
    width: 100%;
    height: 100%;
    aspect-ratio: v-bind('aspectRatio');
    border: none;
  }
  &__toolbar {
    margin-top: 1em;
    display: flex;
    gap: 1em;
  }
}
</style>