<template>
<div class="v-download">

  <v-dropdown class="v-download__button"
    v-bind="bindingDropdown"
    align="right">
  </v-dropdown>

</div>
</template>

<script>
import writeXlsxFile from 'write-excel-file'
export default {
  props: {
    data: { type: Array, required: true, default: [{}] },
    types: { type: Array, required: false, default: ['xlsx','csv'] },
    fileName: { type: [String, Date], required: false, default: new Date() },
  },
  computed: {
    bindingDropdown() {
      return {
        title: { icon: 'download', style: 'font-size:1.5em' },
        items: [
          { label: "Завантажити як XSLX", icon: "table", action: this.downloadAsXLSX, visible: this.types.includes('xlsx') },
          { label: "Завантажити як CSV", icon: "csv", action: this.downloadAsCSV, visible: this.types.includes('csv') },
          { label: "Завантажити як JSON", icon: "file_json", action: this.downloadAsJSON, visible: this.types.includes('json') },
        ]
      }
    },
  },
  methods: {
    flattenObject(obj, prefix = '') {
      const flatObject =  Object.entries(obj).reduce((acc, [key, value]) => {
        const newKey = prefix ? `${prefix}.${key}` : key
        if (Array.isArray(value)) {
          value.forEach((v, i) => {
            if (typeof v === 'object' && v !== null) {
              Object.assign(acc, this.flattenObject(v, `${newKey}.${i}`))
            } else {
              acc[`${newKey}.${i}`] = v
            }
          })
        }
        else if (value && typeof value === 'object') {
          Object.assign(acc, this.flattenObject(value, newKey))
        }
        else acc[newKey] = value
        return acc
      }, {})
      return flatObject
    },
    dataToTable(arrOfObjects, cb = e => e) {
      arrOfObjects = arrOfObjects.map(obj => this.flattenObject(obj))
      const keysSet = arrOfObjects.reduce((acc, obj) => {
        Object.keys(obj).forEach(key => acc.add(key))
        return acc
      }, new Set())
      const keys = Array.from(keysSet)
      return [
        keys.map(e => cb(e)),
        ...arrOfObjects.map(obj => keys.map(key => cb(obj[key])))
      ]
    },
    downloadBlob(content, options) {
      const blob = new Blob([content], options)
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = this.fileName
      a.click()
      URL.revokeObjectURL(url)
    },
    async downloadAsCSV() {
      const arrOfArrays = this.dataToTable(this.data)
      const content = arrOfArrays.join('\r\n')
      this.downloadBlob(content, { type: `text/csv` })
    },
    downloadAsJSON() {
      const content = JSON.stringify(this.data)
      this.downloadBlob(content, { type: `text/json` })
    },
    async downloadAsXLSX() {
      const data = this.dataToTable(this.data, e => ({ value: e }))
      await writeXlsxFile(data, {
        fileName: this.fileName.includes('.') ? `${this.fileName}.xlsx` : this.fileName,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-download {
  font-size: 1rem;
  &__button {
    padding: 0;
  }
}
</style>