<template>
<div class="v-input-search"
  @click="focus()">

  <input class="v-input-search__input"
    ref="input"
    type="text"
    :value="modelValue"
    v-bind="{ disabled, placeholder }"
    @input="input($event)"
    @keyup.enter="submit()">

  <div
    v-if="isSearching"
    v-loading.small="isSearching">
    &emsp;
  </div>

  <i class="material-symbols v-input-search__icon"
    @click="submit()">
    search
  </i>

  <slot/>

</div>
</template>

<script>
export default {
  emits: [
    'update:modelValue',
    'input',
    'submit',
    'clear',
  ],
  props: {
    modelValue: { type: String, requred: false, default: undefined },
    disabled: { type: Boolean, requred: false, default: false },
    autofocus: { type: Boolean, requred: false, default: false },
    placeholder: { type: String, requred: false, default: undefined },
    action: { type: Function, requred: false, default: undefined },
  },
  mounted() {
    if (this.autofocus) this.focus()
  },
  data() {
    return {
      isSearching: false,
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    async submit() {
      this.isSearching = true
      if (this.action) await this.action()
      this.$emit('submit', this.$refs.input.value)
      this.isSearching = false
    },
    input(event) {
      const val = event.target.value
      this.$emit('update:modelValue', val)
      if (val === '') this.$emit('clear')
      else this.$emit('input')
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input-search {
  cursor: text;
  display: inline-flex;
  align-items: center;
  gap: .5em;
  position: relative;
  padding: .5em 1em;
  background-color: $clightgray;
  border-radius: $border-radius;
  &:disabled { opacity: .35; }
  &__input {
    width: 100%;
    height: inherit;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1em !important;
    &::placeholder {
      color: #0004;
    }
  }
  &__icon {
    cursor: pointer;
    font-size: 1.75em;
    color: #333;
    &:hover { opacity: .95 }
    &:active { opacity: .8 }
  }
}
</style>