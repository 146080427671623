<template>
<div class="v-image"
  v-loading.medium="isLoading">

  <div class="v-image__underlay"
    v-if="isExpanded">
    <img class="v-image__image--scaled"
      :src="url"
      @load="isLoadingFull = false">
    <div class="material-symbols v-image__close" 
      @click="isExpanded = false">
      close
    </div>
  </div>

  <img class="v-image__image"
    ref="image"
    :alt="alt">

  <div class="v-image__expand"
    v-if="!isLoading"
    @click="isExpanded = true">
    <i class="material-symbols v-image__expand-icon">
      pan_zoom
    </i>
  </div>

</div>
</template>

<script>
export default {
  props: {
    src: { type: String, required: true, default: null },
    alt: { type: String, required: false, default: '' },
    v: { type: [String, Number], required: false, default: undefined },
    compression: { type: [String, Number], required: false, default: 600 },
  },
  data() {
    return {
      isExpanded: false,
      isLoading: true,
      isLoadingFull: true,
    }
  },
  mounted() {
    const image = this.$refs.image
    image.onload = _ => this.isLoading = false
    image.src = `${this.url}?size=${this.compression}`
  },
  computed: {
    url() {
      if (this.v) return `${this.src}_${this.v}`
      else return this.src
    },
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  display: inline-block;
  position: relative !important;
  &__underlay {
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; bottom: 0;
    background-color: #0008;
  }
  &__close {
    cursor: pointer;
    position: fixed;
    top: .5em;
    right: .5em;
    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    border-radius: 50%;
    font-size: 2.5em;
    background-color: #0005;
    text-align: center;
    color: #fff;
    &:hover { opacity: .85; }
    &:active { opacity: .75; }
  }
  &__expand {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0; right: 0;
    width: 1.25em;
    height: 1.25em;
    font-size: 2em;
    background-color: #0004;
    color: #fff;
    &:hover { opacity: .85; }
    &:active { opacity: .75; }
  }
  &__image {
    width: 100%;
    &--scaled {
      max-width: min(75em, 100%);
      max-height: 100%;
    }
  }
}
</style>