<template>
<div class="v-table"
  ref="root">

  <table class="v-table__table">

    <thead class="v-table__head">
      <tr class="v-table__row">
        <th class="v-table__head-cell"
          v-for="header in headersDisplayed"
          :style="cellStyle({}, header, true)">
          {{ header.label }}
          <v-tooltip class="v-table__tooltip"
            v-if="header.tooltip"
            :text="header.tooltip">
          </v-tooltip>
        </th>
      </tr>
    </thead>

    <tbody class="v-table__body">
      <tr class="v-table__row"
        v-for="item in data">
        <td class="v-table__cell"
          v-for="header in headersDisplayed"
          :style="cellStyle(item, header)">
          <span :class="{ [header.class]: true, 'material-symbols-outlined': header.type=='icon', clickable: typeof header.action == 'function' }"
            @click="typeof header.action == 'function' ? header.action(item) : undefined">
            {{ formatCellContent(item, header) }}
          </span>
        </td>
      </tr>
    </tbody>

    <div
      v-if="!data.length"
      v-empty:10em="placeholder">
    </div>

  </table>

</div>
</template>

<script>
export default {
  props: {
    isScrollLocked: { type: Boolean, required: false, default: false },
    data: { type: Array, required: true, default: [{}] },
    headers: { type: Array, required: false, default: [{}] },
    placeholder: { type: String, required: false, default: 'Пусто' },
    maxHeight: { type: String, required: false, default: 'auto' },
    maxWidth: { type: String, required: false, default: 'auto' },
  },
  mounted() {
    if (!this.isScrollLocked) return
    const tableContainer = this.$refs.root
    const preventPageScroll = event => {
      if (tableContainer.contains(event.target)) {
        event.stopPropagation()
      }
    }
    tableContainer.addEventListener("mouseenter", _ => {
      document.body.style.overflow = "hidden"
      window.addEventListener("wheel", preventPageScroll, { passive: false })
      window.addEventListener("touchmove", preventPageScroll, { passive: false })
    })
    tableContainer.addEventListener("mouseleave", _ => {
      document.body.style.overflow = ""
      window.removeEventListener("wheel", preventPageScroll)
      window.removeEventListener("touchmove", preventPageScroll)
    })
  },
  computed: {
    headersDisplayed() {
      return this.headers.filter(e => e.visible ?? true) 
    },
  },
  methods: {
    formatCellContent(item, header) {
      const { type } = header ?? {}
      let text = typeof header.value == 'function' ? header.value(item) : item[header.value]
      let icon = typeof header.icon == 'function' ? header.icon(item) : header.icon
      if (type=='icon') text = icon
      if (type=='number') text = Number(text)
      if (type=='date') text = this.$formatDate(text)
      if (text===null || text===undefined || Number.isNaN(text)) text = header.placeholder ?? '—'
      text = (header.prefix ?? '') + text + (header.postfix ?? '')
      return text
    },
    cellStyle(item, header, isHeaderCell) {
      const style = header.style ?? {}
      if (header.vertical && isHeaderCell) Object.assign(style, { writingMode: 'vertical-rl' })
      if (header.sticky) Object.assign(style, { position: 'sticky', left: 0, outline: '2px solid #fff' })
      if (header.maxWidth) Object.assign(style, { maxWidth: header.maxWidth })
      if (header.maxHeight) Object.assign(style, { maxHeight: header.maxHeight })
      if (header.nowrap && !isHeaderCell) Object.assign(style, { whiteSpace: 'nowrap', overflow: 'clip' })
      if (header.heatmap && !isHeaderCell) {
        const value = item[header.value]
        const maxValue = header.maxValue ?? 100
        Object.assign(style, {
          background: typeof value=='number' ? this.lighten(this.mix3(value / maxValue * 100), 60) : 'transparent'
        })
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.v-table {
  max-height: v-bind(maxHeight);
  overflow-x: auto;
  &__head {
    z-index: 1;
    position: sticky;
    top: 0;
  }
  &__table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: $border-radius;
    user-select: text;
  }
  &__head-cell {
    padding: 1em;
    font-weight: 700;
    border: 2px solid #fff;
    background-color: #ddd;
  }
  &__cell {
    padding: .75em 1em;
    border: 2px solid #fff;
    text-align: center;
    line-height: 1.35;
  }
  &__row:nth-child(even) &__cell {
    background-color: #fff;
  }
  &__row:nth-child(odd) &__cell {
    background-color: #eee;
  }
}
</style>