<template>
<v-modal width="20.5em"
  :modelValue="modelValue"
  @close="$emit('update:modelValue', false)">

  <template #content>
    <div v-if="icon"
      class="material-symbols v-modal__icon" 
      :style="{ color }">
      {{ icon }}
    </div>
    <div v-if="title" 
      class="v-modal__title"
      style="text-align:center">
      {{ title }}
    </div>
    <div v-if="message" 
      class="v-modal__text"
      v-html="message"
      style="text-align:center">
    </div>
  </template>

  <template #buttons
    v-if="action">
    <button class="button-primary"
      @click="action.action()">
      {{ action.label }}
    </button>
  </template>

</v-modal>
</template>

<script>
import vModal from './v-modal.vue'
export default {
  components: { vModal },
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    message: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    color: { type: String, required: false, default: "#ff4d4d" },
    action: { type: Object, required: false, default: undefined },
  }
}
</script>