function hexToNumber(colorString) {
  return parseInt(colorString.substring(1), 16)
}
function numberToBGR(colorNumber) {
  return  Array.from({length: 3}, (_, i) => Math.floor(colorNumber / 16 ** (2 * i) % 16 ** 2))
}
function interpolate(arr1, arr2, percent) {
  return arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))
}
function normalizeHash(hash, min, max) {
  return Math.floor((hash % (max - min)) + min)
}

function lightenComponent(component) {
  const lightenedValue = component + (255 - component) * percent / 100;
  const lightenedHex = Math.round(lightenedValue).toString(16).padStart(2, '0');
  return lightenedHex;
};
function darkenComponent(component, percent) {
  const darkenedValue = component * (100 - percent) / 100;
  const darkenedHex = Math.round(darkenedValue).toString(16).padStart(2, '0');
  return darkenedHex;
};

const mix3 = function(value, c1, c2, c3) {
  let percentage = Math.max( 0, Math.min(value, 100) )
  let [start, end, v] = percentage < 50 
      ? [hexToNumber(c1), hexToNumber(c2), percentage * 2 ] 
      : [hexToNumber(c2), hexToNumber(c3), (percentage - 50) * 2]
  let interpoled = interpolate(numberToBGR(start), numberToBGR(end), v)
  let hex = interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0)
  return ("#" + hex.toString(16).padStart(6, '0'))
}

const lighten = function(colorString, percent) {
  if (typeof colorString != 'string') return
  if (percent>=100) return '#ffffff'
  if (percent<=0) return colorString
  if (colorString.length == 3) colorString = colorString.replace(/(.)/g, '$1$1')
  const [b, g, r] = numberToBGR(hexToNumber(colorString))
  return '#' +
    ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substring(1) +
    ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substring(1) +
    ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substring(1);
}

const darken = function(colorString, percent) {
  if (typeof colorString != 'string') return
  if (colorString.length == 3) colorString = colorString.replace(/(.)/g, '$1$1')
  const [b, g, r] = numberToBGR(hexToNumber(colorString))
  return `#${darkenComponent(r, percent)}${darkenComponent(g, percent)}${darkenComponent(b, percent)}`
}

const hslToHex = (h,s,l) => {
  l /= 100
  const a = s * Math.min(l, 1-l) / 100
  const f = n => {
    const k = (n + h/30) % 12
    const color = l - a * Math.max(Math.min(k-3, 9-k, 1), -1)
    return Math.round(255*color).toString(16).padStart(2, '0')
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

const stringToHash = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

const stringToHex = str => {
  const hash = stringToHash(str)
  const h = normalizeHash(hash, 0, 360)
  const s = normalizeHash(hash, 60, 75)
  const l = normalizeHash(hash, 42, 58)
  return hslToHex(h, s, l)
}

function getContrastTextColor(hexColor) {
  hexColor = hexColor.replace(/^#/, '')
  if (hexColor.length === 3) {
    hexColor = hexColor.split('').map(c => c + c).join('')
  }
  let r = parseInt(hexColor.substring(0, 2), 16) / 255
  let g = parseInt(hexColor.substring(2, 4), 16) / 255
  let b = parseInt(hexColor.substring(4, 6), 16) / 255
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return luminance > 0.7 ? '#333333' : '#FFFFFF'
}

export default {
  mix3,
  lighten,
  stringToHex,
  getContrastTextColor,
  install: (app, options) => {
    app.config.globalProperties.$red = options.color0
    app.config.globalProperties.$yellow = options.color50
    app.config.globalProperties.$green = options.color100
    app.config.globalProperties.mix3 = value => mix3(value, options.color0, options.color50, options.color100)
    app.config.globalProperties.lighten = lighten
    app.config.globalProperties.darken = darken
    app.config.globalProperties.hslToHex = hslToHex
    app.config.globalProperties.stringToHex = stringToHex
    app.config.globalProperties.getContrastTextColor = getContrastTextColor
  }
}
